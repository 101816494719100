
















import Vue from 'vue'
import Component from "vue-class-component"
import api from "@/api"
import { user } from "@/store"

@Component
export default class RegisterAskConfirmView extends Vue {

  async mounted() {
    if (!user.userData) {
      this.$notify({
        text: 'Сперва необходимо зарегистрироваться.',
        type: 'error'
      })
      this.$router.push('/register')
    }
    if (user.userData && user.userData.email_confirmed) {
      this.$notify({
        text: 'Ваш email уже подтверждён.',
        type: 'error'
      })
      this.$router.push('/login')
    }
  }

  async resendLetter() {
    try {
      if (user.userData) {
        await api.auth.registerResendLetter(user.userData.email)
      }
      this.$notify({
        text: 'Письмо с ссылкой-подтверждением отправлено повторно.',
        type: 'success'
      })
    } catch (e) {
      console.log(e)
    }
  }
}
