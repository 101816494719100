import Login from '@/views/auth/login/Login.vue'
import Register from '@/views/auth/register/Register.vue'
import RegisterConfirm from '@/views/auth/register/RegisterConfirm.vue'
import RegisterAskConfirm from '@/views/auth/register/RegisterAskConfirm.vue'
import ForgotPassword from '@/views/auth/login/ForgotPassword.vue'
import ForgotPasswordAskConfirm from '@/views/auth/login/ForgotPasswordAskConfirm.vue'
import ChangePassword from '@/views/auth/login/ChangePassword.vue'

export default [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/register/confirm',
    component: RegisterAskConfirm
  },
  {
    path: '/register/confirm/:confirmToken',
    props: true,
    component: RegisterConfirm
  },

  {
    path: '/forgot_password',
    component: ForgotPassword
  },
  {
    path: '/forgot_password/confirm',
    component: ForgotPasswordAskConfirm
  },
  {
    path: '/forgot_password/confirm/:confirmToken',
    props: true,
    component: ChangePassword
  },
]