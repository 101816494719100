































import Vue from 'vue'
import Component from "vue-class-component"
import LayoutAuth from "@/layouts/LayoutAuth.vue"
import { user } from "@/store"
import Room from '@/models/table/room'
import api from '@/api'

@Component({
  components: {
    LayoutAuth
  }
})
export default class WithdrawModal extends Vue {
  loading = true
  rooms: Array<Room> = []
  amount = 1
  attemptedToSumbit = false
  valid = false
  submitting = false

  get userData() {
    return user.userData
  }

  validateField(field: string, override?: boolean) {
    if (!this.userData) {
      return false
    }
    if (!this.attemptedToSumbit && !override) {
      return true
    }
    var valid = false
    if (field == 'amount') {
      valid = this.amount <= this.userData.balance ? true : false
    }
    return valid
  }

  validate() {
    var validationResults = []
    validationResults.push(this.validateField('amount'))
    this.valid = validationResults.every((element) => element)
  }

  async confirm() {
    this.attemptedToSumbit = true
    this.validate()
    if (this.valid) {
      this.submitting = true
      try {
        var transferRewardBalanceAction = await api.user.createTransferReward(this.amount)
        this.$notify({
          text: "Запрос отправлен в обработку. Пожалуйста, подождите.",
          type: 'success'
        })
        this.$bvModal.hide('withdraw-modal')
        this.$emit('confirmed')
      } catch (e) {
        this.$notify({
          text: "Ошибка при запросе вывода. Обновите страницу и повторите попытку.",
          type: 'error'
        })
      }
      this.submitting = false
    }
  }
}
