







import Vue from 'vue'
import Component from "vue-class-component"
import NavbarDefault from '@/components/navbars/NavbarDefault.vue'
import { Prop } from "vue-property-decorator"
import { user } from "@/store"
import api from "@/api"

@Component({
  components: {
    NavbarDefault
  }
})
export default class LayoutDefault extends Vue {
  @Prop({default: 10}) cols!: number

  async mounted() {
    var inviteCode = (this.$route.query.invite as any)
    if (inviteCode) {
      user.setInviteCode(inviteCode)
    }
  }
}
