







import Vue from 'vue'
import Component from "vue-class-component"
import NavbarAuth from '@/components/navbars/NavbarAuth.vue'
import { Prop } from "vue-property-decorator"
import { user } from "@/store"
import api from "@/api"

@Component({
  components: {
    NavbarAuth
  }
})
export default class LayoutAuth extends Vue {
  @Prop({default: 10}) cols!: number

  async getUserData() {
    const userData = await api.user.getUser()
    user.setUser(userData)
  }

  async mounted() {
    var inviteCode = (this.$route.query.invite as any)
    if (inviteCode) {
      user.setInviteCode(inviteCode)
    }
  }
}
