










































import Vue from 'vue'
import Component from "vue-class-component"
import LayoutAuth from "@/layouts/LayoutAuth.vue"
import { user } from "@/store"
import QrcodeVue from 'qrcode.vue'
import { Prop } from "vue-property-decorator"
import api from "@/api"
import Payment from '@/models/payment/payment'

@Component({
  components: {
    LayoutAuth,
    QrcodeVue
  }
})
export default class RefillBalanceModal extends Vue {
  @Prop() paymentId!: number
  payment!: Payment

  get toAddress() {
    return this.payment.to_address
  }

  copyToAddress() {
    this.$notify({
      text: "Адрес скопирован!",
      type: 'success'
    })
  }

  copyToDestTag() {
    this.$notify({
      text: "Тэг назначения скопирован!",
      type: 'success'
    })
  }

  async shown() {
    this.payment = await api.payment.getPayment(this.paymentId)
    this.$forceUpdate()
  }

  get userData() {
    return user.userData
  }
}
