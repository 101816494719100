

























































































































































import Vue from 'vue'
import Component from "vue-class-component"
import LayoutDefault from "@/layouts/LayoutDefault.vue"

@Component({
  components: {
    LayoutDefault
  }
})
export default class LandingView extends Vue {
  headerBackgroundStyles = {
    backgroundImage: `url(${require('@/assets/landing/header-2.png')})`,
    // position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    paddingTop: '15rem',
    paddingBottom: '50rem'
  }
  sequenceBackgroundStyles = {
    backgroundImage: `url(${require('@/assets/landing/sequence.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
  cabinetBackgroundStyles = {
    backgroundImage: `url(${require('@/assets/landing/cabinet.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }
}
