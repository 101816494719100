import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '@/views/Landing.vue'
import AuthRouter from './auth'
import DashboardRouter from './dashboard'


Vue.use(VueRouter)

const basicTitle = "| WestWallet Promo"

const routes = [
  {
    path: '/',
    component: Landing
  },
  ...AuthRouter,
  ...DashboardRouter
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  
  if (to?.meta?.title) {
    document.title = to.meta.title
  } else {
    document.title = "WestWallet Promo"
  }
  next()
})

export default router
