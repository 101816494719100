import Room from '@/models/table/room'
import axios, {appBase} from './axios'

const base = `${appBase}/table`

export default class TableApi {
  async getCurrentTable() {
    return (await axios.get(
      `${base}/current`
    )).data.data as Array<Room>
  }

  async openTable() {
    return (await axios.post(
      `${base}/open`
    )).data.data as Array<Room>
  }
}
