const axios = require('axios').create()
import router from '@/router'
import { user } from "@/store"


axios.interceptors.request.use(
  (config: any) => {
    config.withCredentials = true;
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 401) {
      user.logout()
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

export const appBase = process.env.VUE_APP_BASE_URL
export default axios