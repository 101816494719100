import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Notifications from 'vue-notification'
import VueClipboard from 'vue-clipboard2'

library.add(fas)
library.add(fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Notifications)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(BootstrapVue, {
  BModal: {
    bgColor: "#FFFFFF"
  },
  BFormInvalidFeedback: {
    state: false
  }
})

Vue.config.productionTip = false

import '@/assets/css/base.css'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
