import User from '@/models/user/user'
import axios, {appBase} from './axios'

const base = `${appBase}/auth`

export default class AuthApi {
  async login(email: string, password: string, recaptcha_response: string) {
    return (await axios.post(
      `${base}/login`, {
        email,
        password,
        recaptcha_response
      }
    )).data.data as User
  }

  async register(email: string, password: string, password_confirm: string, invite_code: string, recaptcha_response: string) {
    return (await axios.post(
      `${base}/register`, {
        email,
        password,
        password_confirm,
        invite_code,
        recaptcha_response
      }
    )).data.data as User
  }

  async registerResendLetter(email: string) {
    return (await axios.post(
      `${base}/register/resend_letter`, {
        email
      }
    )).data.data as User
  }

  async registerConfirm(email_confirm_secret: string) {
    return (await axios.post(
      `${base}/register/confirm`, {
        email_confirm_secret
      }
    )).data.data as User
  }

  async forgotPassword(email: string, recaptcha_response: string) {
    return (await axios.post(
      `${base}/forgot_password`, {
        email,
        recaptcha_response
      }
    )).data
  }

  async changePassword(forgot_password_token: string, password: string, password_confirm: string, recaptcha_response: string) {
    return (await axios.post(
      `${base}/change_password`, {
        forgot_password_token,
        password,
        password_confirm,
        recaptcha_response
      }
    )).data.data as User
  }

  async logout() {
    return (await axios.post(
      `${base}/logout`,
    )).data
  }
}
