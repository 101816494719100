import AuthApi from './auth'
import UserApi from './user'
import TableApi from './table'
import PaymentApi from './payment'

class Api {
  auth = new AuthApi()
  user = new UserApi()
  table = new TableApi()
  payment = new PaymentApi()
}

const api = new Api()

export default api