
















import Vue from "vue"
import Component from "vue-class-component"
import { user } from "@/store"

@Component
export default class NavbarDefault extends Vue {
  get userData() {
    return user.userData
  }
}
