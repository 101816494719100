















import Vue from 'vue'
import Component from "vue-class-component"
import LayoutAuth from "@/layouts/LayoutAuth.vue"
import { user } from "@/store"
import Room from '@/models/table/room'

@Component({
  components: {
    LayoutAuth
  }
})
export default class InviteModal extends Vue {
  loading = true
  rooms: Array<Room> = []

  get inviteLink() {
    return `https://westwallet.promo/?invite=${this.userData?.invite_code}`
  }

  get userData() {
    return user.userData
  }

  copyInviteLink() {
    this.$notify({
      text: "Ссылка скопирована!",
      type: 'success'
    })
  }
}
