import BalanceAction from '@/models/user/balanceAction'
import BalanceActionData from '@/models/user/balanceActionData'
import User from '@/models/user/user'
import axios, {appBase} from './axios'

const base = `${appBase}/user`

export default class UserApi {
  async getUser() {
    return (await axios.get(
      `${base}`
    )).data.data as User
  }

  async updateUser(user: User) {
    return (await axios.put(
      `${base}`, {
        ...user
      }
    )).data.data as User
  }

  async createTransferReward(amount: number) {
    return (await axios.post(
      `${base}/transfer_reward`, {
        amount
      }
    )).data.data as BalanceAction
  }

  async getBalanceActions(page: number) {
    return (await axios.get(
      `${base}/balance_actions`, {
        params: {
          page
        }
      }
    )).data as BalanceActionData
  }

  async getReferrals() {
    return (await axios.get(
      `${base}/referrals`,
    )).data.data as Array<User>
  }
}
