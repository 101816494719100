
































import Vue from 'vue'
import Component from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { user } from "@/store"
import api from '@/api'
import EstimatedPayment from '@/models/payment/estimatedPayment'

@Component
export default class RefillBalanceModal extends Vue {
  currencyCode = "USDTTRC"
  tokenAmount = 10
  estimatedPayment: EstimatedPayment | null = null
  estimating = false

  async shown() {
    await this.estimatePayment()
  }

  @Watch('currencyCode')
  @Watch('tokenAmount')
  async estimatePayment() {
    if (this.tokenAmount) {
      this.estimating = true
      this.estimatedPayment = await api.payment.estimatePayment(this.currencyCode, this.tokenAmount)
      this.estimating = false
    }
  }

  async generateAddress() {
    var payment = await api.payment.createPayment(this.currencyCode, this.tokenAmount)
    this.$emit('paymentCreated', payment.id)
  }

  get userData() {
    return user.userData
  }
}
