

























import Vue from "vue"
import Component from "vue-class-component"
import { user } from "@/store"

@Component
export default class NavbarAuth extends Vue {
  async logout() {
    await user.logout()
    this.$notify({
      text: 'Вы вышли из аккаунта.',
      type: 'success'
    })
    this.$router.push('/login')
  }

  get userData() {
    return user.userData
  }
}
