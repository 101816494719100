import User from '@/models/user/user'
import { VuexModule, Module, MutationAction, Mutation, Action, getModule } from 'vuex-module-decorators'
import api from "@/api"

@Module({ name: 'user', namespaced: true })
export default class extends VuexModule {
  userData: User | null = null
  inviteCode: string | null = null
  
  @Mutation
  setUser(userData: User) {
    this.userData = userData
  }

  @Mutation
  setInviteCode(inviteCode: string) {
    this.inviteCode = inviteCode
  }

  @Action({commit: 'removeUserData', rawError: true})
  async logout() {
    return await api.auth.logout()
  }

  @Mutation
  removeUserData() {
    this.userData = null
  }
}