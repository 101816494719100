






































import Vue from 'vue'
import Component from "vue-class-component"
import api from "@/api"
import { user } from "@/store"
import VueRecaptcha from 'vue-recaptcha';
import recaptchaPublicKey from '@/constants/recaptchaPublicKey'
import debug from "@/constants/debug"
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    VueRecaptcha
  }
})
export default class RegisterView extends Vue {
  debug = debug

  email = ""
  password = ""
  passwordConfirm = ""
  inviteCode = ""
  agreementAccepted = false
  recaptchaResponse = ""
  recaptchaPublicKey = recaptchaPublicKey
  attemptedToSumbit = false
  valid = false
  submitting = false

  @Watch('email')
  watchEmail(value: string) {
    this.email = value.trim()
  }

  recaptchaVerified(response: any) {
    this.recaptchaResponse = response
  }

  validateField(field: string, override?: boolean) {
    if (!this.attemptedToSumbit && !override) {
      return true
    }
    var valid = false
    if (field == 'email') {
      this.email = this.email.trim()
      valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)
    } else if (field == 'password') {
      valid = this.password ? true: false
    } else if (field == 'passwordConfirm') {
      valid = this.password == this.passwordConfirm
    } else if (field == 'inviteCode') {
      valid = this.inviteCode ? true: false
    } else if (field == 'recaptcha') {
      valid = this.recaptchaResponse ? true: false
      if (debug) {
        valid = true
      }
    }
    return valid
  }

  validate() {
    var validationResults = []
    validationResults.push(this.validateField('email'))
    validationResults.push(this.validateField('password'))
    validationResults.push(this.validateField('passwordConfirm'))
    validationResults.push(this.validateField('inviteCode'))
    validationResults.push(this.validateField('recaptcha'))
    this.valid = validationResults.every((element) => element)
  }

  async mounted() {
    if (user.userData) {
      this.$router.push('/dashboard')
      return
    }
    if (user.inviteCode) {
      this.inviteCode = user.inviteCode
    }
  }

  async submit() {
    this.submitting = true
    try {
      this.attemptedToSumbit = true
      this.validate()
      if (this.valid) {
        const userData = await api.auth.register(
          this.email, this.password, this.passwordConfirm, this.inviteCode, this.recaptchaResponse
        )
        user.setUser(userData)
        this.$router.push('/register/confirm')
      }
    } catch (e) {
      this.$notify({
        text: 'Не удалось зарегистрироваться, попробуйте указать другой email.',
        type: 'error'
      })
    }
    this.submitting = false
  }
}
