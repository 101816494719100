



























import Vue from 'vue'
import Component from "vue-class-component"
import api from "@/api"
import { user } from "@/store"
import VueRecaptcha from 'vue-recaptcha';
import recaptchaPublicKey from '@/constants/recaptchaPublicKey'
import debug from "@/constants/debug"

@Component({
  components: {
    VueRecaptcha
  }
})
export default class ForgotPasswordView extends Vue {
  debug = debug

  email = ""
  password = ""
  bindSessionToIP = true
  recaptchaResponse = ""
  recaptchaPublicKey = recaptchaPublicKey
  submitting = false
  attemptedToSumbit = false
  valid = false

  recaptchaVerified(response: any) {
    this.recaptchaResponse = response
  }

  validateField(field: string, override?: boolean) {
    if (!this.attemptedToSumbit && !override) {
      return true
    }
    var valid = false
    if (field == 'email') {
      valid = this.email ? true : false
    } else if (field == 'recaptcha') {
      valid = this.recaptchaResponse ? true: false
      if (debug) {
        valid = true
      }
    }
    return valid
  }

  validate() {
    var validationResults = []
    validationResults.push(this.validateField('email'))
    validationResults.push(this.validateField('recaptcha'))
    this.valid = validationResults.every((element) => element)
  }

  async submit() {
    this.submitting = true
    try {
      this.attemptedToSumbit = true
      this.validate()
      if (this.valid) {
        await api.auth.forgotPassword(this.email, this.recaptchaResponse)
        this.$router.push('/forgot_password/confirm')
      }
    } catch (e) {
      this.$notify({
        text: 'Неудачная попытка входа.',
        type: 'error'
      })
    }
    this.submitting = false
  }
}
