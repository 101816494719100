





















import Vue from 'vue'
import Component from "vue-class-component"
import LayoutAuth from "@/layouts/LayoutAuth.vue"
import { user } from "@/store"
import api from "@/api"
import WithdrawModal from "@/components/modals/WithdrawModal.vue"
import BalanceActionData from '@/models/user/balanceActionData'
import User from '@/models/user/user'
const moment = require('moment')

@Component({
  components: {
    LayoutAuth,
    WithdrawModal
  }
})
export default class ReferralsView extends Vue {
  tableBusy = false
  loading = true
  page = 1
  data: Array<User> = []

  fields = [
    { key: 'show_details', label: '', class: 'fit' },
    { key: 'masked_email', label: 'Email' },
  ]

  backgroundStyles = {
    backgroundImage: `url(${require('@/assets/dashboard-background-2.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    paddingTop: '5rem',
    paddingBottom: '10rem'
  }

  async currentPageChanged(page: number) {
    this.page = page
    this.tableBusy = true
    await this.getData()
    this.tableBusy = false
  }

  async withdrawConfirmed() {
    await this.mounted()
  }

  get userData() {
    return user.userData
  }

  async getUser() {
    const userData = await api.user.getUser()
    user.setUser(userData)
  }

  async getData() {
    this.data = await api.user.getReferrals()
  }

  async mounted() {
    this.loading = true
    await this.getUser()
    await this.getData()
    this.loading = false
  }
}
