import Dashboard from '@/views/dashboard/Dashboard.vue'
import Balance from '@/views/dashboard/Balance.vue'
import Referrals from '@/views/dashboard/Referrals.vue'

export default [
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/balance',
    component: Balance
  },
  {
    path: '/referrals',
    component: Referrals
  },
]