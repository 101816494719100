





import Vue from 'vue'
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import api from "@/api"
import { user } from "@/store"
import recaptchaPublicKey from '@/constants/recaptchaPublicKey'

@Component
export default class RegisterConfirmView extends Vue {
  @Prop() confirmToken!: string

  async mounted() {
    try {
      const userData = await api.auth.registerConfirm(this.confirmToken)
      user.setUser(userData)
      if (user.userData?.email_confirmed) {
        this.$notify({
          text: 'Ваш email успешно подтверждён. Теперь вы можете войти.',
          type: 'success'
        })
        this.$router.push('/login')
      }
    } catch {
      this.$notify({
        text: 'Некорректная ссылка подтверждения email.',
        type: 'error'
      })
      this.$router.push('/register/confirm')
    }
  }
}
