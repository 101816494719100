










































import Vue from 'vue'
import Component from "vue-class-component"
import LayoutAuth from "@/layouts/LayoutAuth.vue"
import { user } from "@/store"
import api from "@/api"
import WithdrawModal from "@/components/modals/WithdrawModal.vue"
import BalanceActionData from '@/models/user/balanceActionData'
const moment = require('moment')

@Component({
  components: {
    LayoutAuth,
    WithdrawModal
  }
})
export default class BalanceView extends Vue {
  tableBusy = false
  loading = true
  page = 1
  data: BalanceActionData | null = null

  fields = [
    { key: 'amount', label: 'Сумма' },
    { key: 'account_type', label: 'Счёт' },
    { key: 'status', label: 'Статус' },
    { key: 'date_time', label: 'Дата', 
      formatter: (value: Date) => {
        return moment(value).format("DD.MM HH:mm")
      }
    }, 
  ]

  backgroundStyles = {
    backgroundImage: `url(${require('@/assets/dashboard-background-2.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    paddingTop: '5rem',
    paddingBottom: '10rem'
  }

  async currentPageChanged(page: number) {
    this.page = page
    this.tableBusy = true
    await this.getTransferBalanceActions()
    this.tableBusy = false
  }

  async withdrawConfirmed() {
    await this.mounted()
  }

  get userData() {
    return user.userData
  }

  async getUser() {
    const userData = await api.user.getUser()
    user.setUser(userData)
  }

  async getTransferBalanceActions() {
    this.data = await api.user.getBalanceActions(this.page)
  }

  async mounted() {
    this.loading = true
    await this.getUser()
    await this.getTransferBalanceActions()
    this.loading = false
  }
}
