




















































































import Vue from 'vue'
import Component from "vue-class-component"
import LayoutAuth from "@/layouts/LayoutAuth.vue"
import { user } from "@/store"
import api from "@/api"
import Room from '@/models/table/room'
import InviteModal from '@/components/modals/InviteModal.vue'
import RefillBalanceModal from '@/components/modals/RefillBalanceModal.vue'
import RefillBalanceAddressModal from '@/components/modals/RefillBalanceAddressModal.vue'

@Component({
  components: {
    LayoutAuth,
    InviteModal,
    RefillBalanceModal,
    RefillBalanceAddressModal
  }
})
export default class DashboardView extends Vue {
  loading = true
  rooms: Array<Room> = []
  paymentId: number | null = null
  backgroundStyles = {
    backgroundImage: `url(${require('@/assets/dashboard-background-2.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    paddingTop: '5rem',
    paddingBottom: '15rem'
  }

  paymentCreated(paymentId: number) {
    console.log(paymentId)
    this.paymentId = paymentId
    this.$bvModal.hide('refill-balance-modal')
    this.$bvModal.show('refill-balance-address-modal')
  }

  get inviteLink() {
    return `https://westwallet.promo/?invite=${this.userData?.invite_code}`
  }

  get userData() {
    return user.userData
  }

  copyInviteLink() {
    this.$notify({
      text: "Ссылка скопирована!",
      type: 'success'
    })
  }

  async getCurrentTable() {
    this.rooms = await api.table.getCurrentTable()
  }

  async getUser() {
    const userData = await api.user.getUser()
    user.setUser(userData)
  }

  async updateUser() {
    if (this.userData) {
      const userData = await api.user.updateUser(this.userData)
      user.setUser(userData)
      this.$notify({
        text: "Настройки сохранены!",
        type: 'success'
      })
    }
  }

  async mounted() {
    this.loading = true
    await this.getUser()
    await this.getCurrentTable()
    this.loading = false
  }

  async openTable() {
    this.rooms = await api.table.openTable()
    await this.getUser()
    this.$forceUpdate()
  }
}
