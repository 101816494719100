import EstimatedPayment from '@/models/payment/estimatedPayment'
import Payment from '@/models/payment/payment'
import axios, {appBase} from './axios'

const base = `${appBase}/payment`

export default class PaymentApi {

  async estimatePayment(currency_code: string, token_amount: number) {
    return (await axios.post(
      `${base}/estimate`,
      {
        currency_code,
        token_amount
      }
    )).data.data as EstimatedPayment
  }

  async getPayment(paymentId: number) {
    return (await axios.get(
      `${base}/${paymentId}`
    )).data.data as Payment
  }

  async createPayment(currency_code: string, token_amount: number) {
    return (await axios.post(
      `${base}/`,
      {
        currency_code,
        token_amount
      }
    )).data.data as Payment
  }
}
